var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", [
    _vm._m(0),
    _vm._m(1),
    _c(
      "tbody",
      _vm._l(_vm.parentInfo, function (item, idx) {
        return _c("tr", { key: idx, staticStyle: { height: "25px" } }, [
          _c("td", [
            _vm._v(_vm._s(item.tableVslCdA) + " " + _vm._s(item.tableVoyNoA)),
          ]),
          _c("td", [_vm._v(_vm._s(item.tableSchEta))]),
          _c("td", { staticStyle: { color: "#008000" } }, [
            _vm._v(_vm._s(item.tableVslCdC) + " " + _vm._s(item.tableVoyNoC)),
          ]),
          _c(
            "td",
            {
              staticStyle: {
                color: "#008000",
                "border-right": "1px solid #e1e3eb",
              },
            },
            [_vm._v(_vm._s(item.tableAisEta))]
          ),
          _c("td", [
            _vm._v(_vm._s(item.tableVslCdB) + " " + _vm._s(item.tableVoyNoB)),
          ]),
          _c("td", [_vm._v(_vm._s(item.tableSchEtd))]),
          _c("td", { staticStyle: { color: "#008000" } }, [
            _vm._v(_vm._s(item.tableVslCdD) + " " + _vm._s(item.tableVoyNoD)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "130px" } }),
      _c("col", { attrs: { width: "170px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "ETA_ETD_under_table_tr" }, [
        _c("th", [_vm._v(" VSL/VOY(DT) ")]),
        _c("th", [_vm._v(" Schedule ETA(DT) ")]),
        _c("th", [_vm._v(" VSL/VOY(DT) ")]),
        _c("th", [_vm._v(" AIS ETA(DT) ")]),
        _c("th", [_vm._v(" VSL/VOY(DT) ")]),
        _c("th", [_vm._v(" Schedule ETD(DT) ")]),
        _c("th", [_vm._v(" VSL/VOY(DT) ")]),
        _c("th", [_vm._v(" AIS ETD(DT) ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }